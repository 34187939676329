import React from "react";
import { Button, Layout, Menu, Modal, Typography } from "antd";
import {
  UserOutlined,
  ExclamationCircleOutlined,
  DollarCircleOutlined,
  ShoppingOutlined,
  ThunderboltOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { logout } from "../../store/user/userSlice";
import { Link } from "react-router-dom";
import { grey } from "@ant-design/colors";
const { Header, Content, Footer, Sider } = Layout;

export const DefaultLayout = ({ children }: { children: React.Component }) => {
  const dispatch = useDispatch();
  return (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div
          className="logo"
          style={{ height: 64, display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Typography.Title style={{ margin: 0, color: grey[0] }}>M&H</Typography.Title>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<UnorderedListOutlined />}>
            <Link to="/clients" className="nav-text">
              Clients
            </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<DollarCircleOutlined />}>
            <Link to="/pricingLists" className="nav-text">
              Pricing Lists
            </Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ShoppingOutlined />}>
            <Link to="/orders" className="nav-text">
              Orders
            </Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<UserOutlined />}>
            <Link to="/customers/search" className="nav-text">
              Customers
            </Link>
          </Menu.Item>
          <Menu.Item key="discounts" icon={<ThunderboltOutlined />}>
            <Link to="/discounts" className="nav-text">
              Discounts
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Header
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
          }}
        >
          <div className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            style={{ marginRight: 150, display: "flex", justifyContent: "flex-end" }}
          >
            <Menu.Item key="1">
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure you want to logout?",
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => dispatch(logout()),
                    okText: "Logout",
                  });
                }}
              >
                Logout
              </Button>
            </Menu.Item>
          </Menu>
        </Header>
        <Content
          style={{
            margin: "64px 0 0",
            overflow: "initial",
            minHeight: "calc(100vh - 64px - 70px)",
          }}
        >
          <div className="site-layout-background" style={{ padding: 24, textAlign: "center" }}>
            {children}
          </div>
        </Content>
        {/*<Footer style={{ textAlign: "center" }}>Ant Design ©2018 Created by Ant UED</Footer>*/}
      </Layout>
    </Layout>
  );
};
