import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Collection } from "../../interfaces/Collection";
import { endpoints } from "../../api/endpoints";
import { api, extractStandardResponseData } from "../../api";
import { RootState } from "../store";

export const collectionAdapter = createEntityAdapter<Collection>({
  selectId: (model) => model.id,
});

export const collectionsFetchAll = createAsyncThunk("collection/collectionsFetchAll", async () =>
  api.get(endpoints.collections).then(extractStandardResponseData)
);

const { actions, reducer } = createSlice({
  name: "collections",
  initialState: collectionAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(collectionsFetchAll.fulfilled, collectionAdapter.setAll);
  },
});

export const collectionActions = actions;
export const collectionsReducers = reducer;
export const collectionSelectors = collectionAdapter.getSelectors((s: RootState) => s.collections);
