import axios, { AxiosResponse, ResponseType } from "axios";
import { message } from "antd";
import * as Sentry from "@sentry/react";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    ["Content-Type"]: "application/json",
  },
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    Sentry.captureException(error, { extra: { response: error.response.data } });
    console.error(error);
    if (error.response?.data?.message) {
      message.error(error.response?.data?.message);
    }
    return Promise.reject(error);
  }
);

export const setAxiosToken = (token: string) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const setShopHeader = (shopId: string) =>
  (api.defaults.headers.common["Shopy-Shop-ID"] = shopId);

// Standard Axios response from API is in below format. This is helper to extract data
// const res = await api.get()
// res => {
//   data: {
//     data: T | T[]
//   }
// }
export const extractStandardResponseData = (res: AxiosResponse) => res.data.data;
