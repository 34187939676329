import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { api, setAxiosToken, setShopHeader } from "../../api";
import { endpoints } from "../../api/endpoints";
import { localStorageApi } from "../../api/localStorage";

interface UserState {
  id: string;
  name: string;
  email: string;
  token: string;
  loginLoading: boolean;
  loginFailedMessage: string;
}

const userSlice = createSlice({
  name: "user",
  initialState: {} as Partial<UserState>,
  reducers: {
    loginRequest: (state) => ({
      ...state,
      loginLoading: true,
      loginFailedMessage: undefined,
    }),
    loginSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      loginFailedMessage: undefined,
      loginLoading: false,
    }),
    loginFailure: (state, action) => ({ ...state, loginLoading: false }),

    refreshUserRequest: (state) => ({
      ...state,
      loginLoading: true,
    }),
    refreshUserSuccess: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    logout: (state) => ({}),
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;

export const login = (email: string, password: string): AppThunk => async (dispatch) => {
  dispatch(userActions.loginRequest());
  try {
    const {
      data: { data },
    } = await api.post(endpoints.login, { email, password });
    localStorageApi.saveToken(data.token);
    setAxiosToken(data.token as string);
    dispatch(userActions.loginSuccess(data));
    setShopHeader(data.primaryShopId);
  } catch (e) {
    console.error(e, e.response);
    dispatch(userActions.loginFailure(e.response?.data));
  }
};

export const refreshUser = (token: string): AppThunk => async (dispatch) => {
  dispatch(userActions.refreshUserRequest());

  try {
    const {
      data: { data },
    } = await api.get(endpoints.refreshUser);
    setShopHeader(data.primaryShopId);
    dispatch(userActions.loginSuccess({ ...data, token }));
  } catch (e) {
    console.error(e, e.response);
    dispatch(userActions.loginFailure(e.response?.data));
  }
};

export const logout = (): AppThunk => async (dispatch) => {
  setAxiosToken("");
  setShopHeader("");
  localStorageApi.saveToken("");
  dispatch(userActions.logout());
};
