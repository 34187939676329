import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Breadcrumb, Col, PageHeader, Row } from "antd";
import React from "react";
import { PromotionForm } from "./PromotionForm";
import { useSelector } from "react-redux";
import { promotionSelector } from "../../store/promotionsSlice";
import { RootState } from "../../store/store";

export const EditPromotion = () => {
  const {
    path,
    params: { id },
  } = useRouteMatch();
  const history = useHistory();

  const promotion = useSelector((state: RootState) => promotionSelector.selectById(state, id));

  return (
    <Row className="page promotions">
      <Switch>
        <Route exact path={path}>
          <Breadcrumb>
            <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item key="promotions">Discounts</Breadcrumb.Item>
            <Breadcrumb.Item key="edit">Edit</Breadcrumb.Item>
          </Breadcrumb>
          <Col sm={24}>
            <PageHeader
              title="Edit Discount"
              className="site-page-header"
              onBack={() => history.push("/discounts")}
              extra={[]}
            />
          </Col>
          <Col sm={24}>
            <PromotionForm promotion={promotion} />
          </Col>
        </Route>
      </Switch>
    </Row>
  );
};
