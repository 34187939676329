import React, { useEffect } from "react";
import { Order } from "../../../interfaces/Order";
import { useAppDispatch } from "../../../store/store";
import { useForm } from "antd/es/form/Form";
import Modal from "antd/es/modal/Modal";
import { Checkbox, Form, Input, Select } from "antd";
import { cancelOrder } from "../../../store/ordersSlice/ordersSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { message } from "antd/es";

interface IProps {
  order: Order;
  onClose: () => void;
}

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const orderCancelReasons = [
  { label: "Customer changed/cancelled order", value: "customer" },
  { label: "Fraudulent order", value: "fraud" },
  { label: "Items unavailable", value: "inventory" },
  { label: "Payment declined", value: "declined" },
  { label: "Other", value: "other" },
];

export const CancelOrderModal = ({ order, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    form && form.setFieldsValue(order);
  }, [order, form]);

  const submit = async () => {
    const formData = form.getFieldsValue();
    setLoading(true);
    await dispatch(cancelOrder(formData)).then(unwrapResult);
    setLoading(false);
    message.success("Order cancelled successfully.");
    onClose();
  };

  return (
    <Modal
      title="Cancel order"
      visible={true}
      onOk={submit}
      onCancel={onClose}
      okText="Cancel order"
      cancelText="Keep order"
      okButtonProps={{ loading }}
    >
      <Form form={form} {...layout}>
        <Form.Item name="orderId" initialValue={order.id} hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Restock items"
          name="restock"
          valuePropName="checked"
          initialValue={true}
          hidden
        >
          <Checkbox />
        </Form.Item>
        <Form.Item label="Reason for cancellation" name="reason" initialValue={"Other"}>
          <Select options={orderCancelReasons} />
        </Form.Item>
        <Form.Item
          label="Notify customer"
          name="notify"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};
