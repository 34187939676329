import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Collapse, Input, Modal, PageHeader, Space } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { pricingListGet, pricingListSelectors } from "../../store/pricingLists/pricingListSlice";
import { RootState, useAppDispatch } from "../../store/store";
import { debounce } from "lodash";
import { Product } from "../../interfaces/Product";
import { PricingList } from "../../interfaces/PricingList";
import { PricingListHeader } from "../../components/pricingListHeader/PricingListHeader";
import { PricingListPanel } from "../../components/pricingListPanel/PricingListPanel";
import { clientSelectors } from "../../store/clientsSlice/clientsSlice";
import { collectionSelectors } from "../../store/collectionSlice/collectionsSlice";
import { Collection } from "../../interfaces/Collection";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { syncShop } from "../../store/shop/shopSlice";
const { Panel } = Collapse;

const filterProducts = (products: Product[] | undefined, query: string) => {
  if (!query || query.length < 3) {
    return products;
  }
  const searchQuery = new RegExp(query, "i");
  return (products || []).filter(
    (p) =>
      p.title.match(searchQuery) ||
      p.variants.some((v) => v.title.match(searchQuery) || (v.sku && v.sku.match(searchQuery)))
  );
};

export const PricingListView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const pricingList: PricingList | undefined = useSelector((s: RootState) =>
    pricingListSelectors.selectById(s, id)
  );

  const clients = useSelector(clientSelectors.selectAll);
  const collections = useSelector(collectionSelectors.selectAll);
  const products = useSelector((state: RootState): Product[] => {
    const productIds = Array.from(
      new Set((pricingList?.discountedPrices || []).map((pl) => pl.productId))
    );
    const pricingListClients = clients.filter((c) => c.pricingListId === pricingList?.id);
    const [client] = pricingListClients;
    const collection = collections.find(({ id }) => id === client?.collectionId);
    if (pricingListClients.length > 1 || !client || !collection) {
      return productIds.map((id) => state.shop.products[id]);
    } else {
      return productIds
        .filter((pid) => collection.collectionProducts.find(({ productId }) => productId === pid))
        .map((id) => state.shop.products[id]);
    }
  });

  useEffect(() => {
    pricingList?.discountedPrices === undefined && dispatch(pricingListGet(id));
  }, [pricingList]);

  const [searchQuery, setSearchQuery] = useState("");
  const searchWithFilter = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
    }, 300),
    [searchQuery]
  );

  if (pricingList === undefined || pricingList.discountedPrices === undefined) {
    return <></>;
  }

  const getCollection = (pricingListId: number) => {
    const client = clients?.find((c) => c.pricingListId === pricingListId);
    if (client) {
      return collections?.find((col) => col.id === client.collectionId);
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item key="clients" onClick={() => history.push("/pricingLists")}>
          Pricing Lists
        </Breadcrumb.Item>
        <Breadcrumb.Item key="clients">{pricingList?.title}</Breadcrumb.Item>
      </Breadcrumb>
      <Col sm={24}>
        <PageHeader
          title={pricingList?.title}
          className="site-page-header"
          onBack={() => history.push("/pricingLists")}
          // style={{ width: 150 }}
          extra={
            <Space>
              <Input
                addonBefore="Search"
                style={{ width: 250 }}
                onChange={(e) => {
                  e.persist();
                  searchWithFilter(e.target.value);
                }}
              />
              <Button
                type="primary"
                onClick={() => history.push(`/pricingLists/${pricingList.id}/company_issues`)}
              >
                Company Issues
              </Button>
              <Button
                type="primary"
                onClick={() => history.push(`/pricingLists/${pricingList.id}/tax_exemption`)}
              >
                Tax Exemption
              </Button>
            </Space>
          }
        />
      </Col>
      <Col sm={24}>
        <Collapse className="product-list" style={{ textAlign: "left" }} accordion>
          {
            // @ts-ignore
            filterProducts(products || [], searchQuery).map((product) => (
              <Panel
                key={product.id}
                header={
                  <PricingListHeader
                    pricingListId={pricingList?.id}
                    product={product}
                    collection={getCollection(pricingList?.id) as Collection}
                  />
                }
                showArrow={false}
              >
                <PricingListPanel product={product} pricingList={pricingList} />
              </Panel>
            ))
          }
        </Collapse>
      </Col>
    </>
  );
};
