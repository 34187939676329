import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../store";
import { api } from "../../api";
import { endpoints } from "../../api/endpoints";
import { mapify } from "../../utils";
import { Client } from "../../interfaces/Client";
import { collectionsFetchAll } from "../collectionSlice/collectionsSlice";
import { clientsFetchAll } from "../clientsSlice/clientsSlice";
import { fetchPricingLists } from "../pricingLists/pricingListSlice";

const { actions, reducer } = createSlice({
  name: "shop",
  initialState: { products: [], clients: [] },
  reducers: {
    getShopRequest: (state) => ({
      ...state,
      getShopLoading: true,
    }),
    getShopSuccess: (state, action) => {
      const clients = action.payload.clients.map((client: Client) => ({
        ...client,
      }));

      return {
        ...state,
        ...action.payload,
        products: mapify(action.payload.products),
        clients: mapify(clients),
        getShopLoading: false,
      };
    },
    getShopFailure: (state, action) => ({ ...state, getShopLoading: false }),

    syncShopRequest: (state) => ({ ...state, syncShopLoading: true }),
    syncShopSuccess: (state) => ({ ...state, syncShopLoading: false }),
    syncShopFailure: (state, action) => ({
      ...state,
      syncShopLoading: false,
      syncShopErrorMsg: action.payload.msg,
    }),
  },
});

export const getShop = (): AppThunk => async (dispatch) => {
  dispatch(actions.getShopRequest());
  try {
    const {
      data: { data },
    } = await api.get(endpoints.shop);
    dispatch(actions.getShopSuccess(data));
  } catch (e) {
    console.error(e);
    dispatch(actions.getShopFailure(e.response?.data));
  }
};

export const syncShop = (): AppThunk => async (dispatch) => {
  dispatch(actions.syncShopRequest());
  try {
    await api.post(endpoints.syncShop);
    dispatch(actions.syncShopSuccess());
  } catch (e) {
    console.error(e);
    dispatch(actions.syncShopFailure(e.response?.data));
  }
};

export const resetShopState = createAsyncThunk(
  "shops/resetShopState",
  async (_params, { dispatch }: { dispatch: AppDispatch }) => {
    return Promise.all([
      dispatch(clientsFetchAll()),
      dispatch(collectionsFetchAll()),
      dispatch(fetchPricingLists()),
    ]);
  }
);

export const shopActions = actions;
export const shopReducers = reducer;
