import React, { useEffect, useState } from "react";
import "./Login.scss";
import { useDispatch } from "react-redux";
import { login } from "../../store/user/userSlice";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { Redirect } from "react-router-dom";

interface IProps {
  isLoggedIn: boolean;
}

export const Login = ({ isLoggedIn }: IProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => setIsAuthenticated(isLoggedIn), [isLoggedIn]);

  const onSubmit = (e: any) => {
    dispatch(login(email, password));
  };

  return isAuthenticated ? (
    <Redirect to={{ pathname: "/clients" }} />
  ) : (
    <div className="page login d-center">
      <Card title="Login" className="auth-card">
        <Form name="login" onFinish={onSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password onChange={(e) => setPassword(e.target.value)} />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
