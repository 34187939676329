import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Promotion } from "../interfaces/Promotion";
import { api, extractStandardResponseData } from "../api";
import { Page } from "../interfaces/Page";
import { emptyArray } from "../utils";

export const promotionAdapter = createEntityAdapter<Promotion>();

export const listPromotions = createAsyncThunk("promotions/listPromotions", async (filters: any) =>
  api.get("/promotions", { params: filters }).then((res) => res.data)
);

export const createPromotion = createAsyncThunk(
  "promotions/createPromotion",
  async (payload: Partial<Promotion>) =>
    api.post("/promotions", payload).then(extractStandardResponseData)
);

export const updatePromotion = createAsyncThunk(
  "promotions/updatePromotion",
  async (promotion: Partial<Promotion>) =>
    api.patch(`/promotions/${promotion.id}`, promotion).then(extractStandardResponseData)
);

export const deletePromotion = createAsyncThunk(
  "promotions/deletePromotion",
  async ({ id }: Promotion) => api.delete(`/promotions/${id}`).then(extractStandardResponseData)
);

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState: promotionAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPromotion.fulfilled, promotionAdapter.upsertOne);
    builder.addCase(listPromotions.fulfilled, (state, { payload: { data } }) => {
      return promotionAdapter.upsertMany(state, data);
    });
    builder.addCase(updatePromotion.fulfilled, promotionAdapter.upsertOne);
    builder.addCase(deletePromotion.fulfilled, (state, { payload: { id } }) =>
      promotionAdapter.removeOne(state, id)
    );
  },
});

const promotionPaginationAdapter = createEntityAdapter<Page>();

export const promotionsPaginationSlice = createSlice({
  name: "promotionsPagination",
  initialState: { pages: promotionPaginationAdapter.getInitialState(), total: 0 },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listPromotions.fulfilled, (state, { payload }) => {
      return {
        total: payload.total,
        pages: promotionPaginationAdapter.upsertOne(
          { ...state.pages },
          { id: payload.page, items: payload.data.map(({ id }: Promotion) => id) }
        ),
      };
    });
    builder.addCase(createPromotion.fulfilled, () => ({
      pages: promotionPaginationAdapter.getInitialState(),
      total: 0,
    }));
    builder.addCase(deletePromotion.fulfilled, () => ({
      pages: promotionPaginationAdapter.getInitialState(),
      total: 0,
    }));
  },
});

const paginationAdapterSelector = promotionPaginationAdapter.getSelectors(
  (s: RootState) => s.promotionsPagination.pages
);

export const promotionPaginationSelector = {
  pages: paginationAdapterSelector,
  get: (state: RootState, page: number): Promotion[] => {
    return (paginationAdapterSelector
      .selectById(state, page)
      ?.items?.map((id) => promotionSelector.selectById(state, id)) || emptyArray) as Promotion[];
  },
  total: (s: RootState) => s.promotionsPagination.total,
};

export const promotionSelector = promotionAdapter.getSelectors((s: RootState) => s.promotions);
