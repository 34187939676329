import { Switch } from "antd";
import React, { useEffect, useState } from "react";

interface IProps {
  currentStatus: boolean;
  onSubmit: (active: boolean) => void;
}

export const StatusToggleButton = ({ currentStatus, onSubmit }: IProps) => {
  const [status, setStatus] = useState(false);

  useEffect(() => setStatus(currentStatus), [currentStatus]);
  return <Switch checked={status} onChange={() => onSubmit(!status)} />;
};
