import React, { useCallback, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  PageHeader,
  Spin,
  Row,
  Table,
  Modal,
  Tag,
  Input,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { syncShop } from "../../store/shop/shopSlice";
import { RootState } from "../../store/store";
import { ClientFormModal } from "../../components/client_form_modal/ClientFormModal";
import { Client } from "../../interfaces/Client";
import { StatusToggleButton } from "../../components/status_toggle_button/StatusToggleButton";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { queryToRegex, resetBodyOverflow } from "../../utils";
import { gold } from "@ant-design/colors";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { pricingListSelectors } from "../../store/pricingLists/pricingListSlice";
import { collectionSelectors } from "../../store/collectionSlice/collectionsSlice";
import { clientSelectors, clientUpdate } from "../../store/clientsSlice/clientsSlice";
import { debounce } from "lodash";

export const Clients = () => {
  const dispatch = useDispatch();

  const shop = useSelector((state: RootState) => state.shop);
  const clients = useSelector(clientSelectors.selectAll);
  // @ts-ignore
  const shopLoading = useSelector((state: RootState) => state.shop.getShopLoading);

  const [clientFormSource, setClientFormSource] = useState<Partial<Client>>();

  // @ts-ignore
  const syncingShop = useSelector((state: RootState) => state.shop.syncShopLoading);

  const pricingListEntries = useSelector(pricingListSelectors.selectEntities);

  const collections = useSelector(collectionSelectors.selectEntities);
  const [searchQuery, setSearchQuery] = useState("");

  const filterClients = useCallback(
    (clients: Client[] | undefined, query: string) => {
      if (!query || query.length < 2) {
        return clients;
      }
      const searchQuery = queryToRegex(query);
      return (clients || []).filter((c) => {
        return (
          c.tag.match(searchQuery) ||
          c.name.match(searchQuery) ||
          collections[c.collectionId]?.title?.match(searchQuery) ||
          pricingListEntries[c.pricingListId]?.title?.match(searchQuery)
        );
      });
    },
    [collections, pricingListEntries]
  );

  const searchWithFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [searchQuery]
  );

  return (
    <Row className="page dashboard">
      <Breadcrumb>
        <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item key="clients">Clients</Breadcrumb.Item>
      </Breadcrumb>

      <Col sm={24}>
        <PageHeader
          title={shop.name}
          extra={[
            <Row>
              <Col sm={14}>
                <Input
                  addonBefore="search"
                  onChange={(e) => {
                    e.persist();
                    searchWithFilter(e.target.value);
                  }}
                />
              </Col>
              <Col sm={10} style={{ textAlign: "right" }}>
                <Space>
                  <Button
                    type="primary"
                    key="sync-shop"
                    style={{ background: gold.primary, borderColor: gold[2] }}
                    loading={syncingShop}
                    onClick={() =>
                      Modal.confirm({
                        title:
                          "This is an expensive operation, are you sure you want to sync this shop right now?",
                        icon: <ExclamationCircleOutlined />,
                        onOk: () => dispatch(syncShop()),
                        okText: "Sync Shop",
                      })
                    }
                  >
                    Sync Shop
                  </Button>

                  <Button type="primary" key="new-client" onClick={() => setClientFormSource({})}>
                    Add Client
                  </Button>
                </Space>
              </Col>
            </Row>,
          ]}
        />
      </Col>

      <Col sm={24}>
        {shopLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
        {!shopLoading && (
          <>
            <Table dataSource={filterClients(clients, searchQuery)} rowKey={"id"}>
              <Table.Column title="Shopify ID" dataIndex="id" key="id" />
              <Table.Column title="Client Name" dataIndex="name" key="name" />
              <Table.Column
                title="User Tag"
                dataIndex="tag"
                key="tag"
                render={(text) => <Tag>{text}</Tag>}
              />
              <Table.Column
                title="Collection"
                dataIndex="collectionId"
                key="collectionId"
                render={(id) => <Tag>{collections[id]?.title}</Tag>}
              />
              <Table.Column
                title="Price List Name"
                key="pricingListId"
                render={(text, client: Client) => (
                  <Tag color="blue">
                    <Link to={`/pricingLists/${client.pricingListId}`}>
                      {pricingListEntries[client.pricingListId]?.title}
                    </Link>
                  </Tag>
                )}
              />
              <Table.Column
                title="Default Base Price List"
                key="basePriceList"
                dataIndex="basePriceList"
              />
              <Table.Column
                title="Actions"
                render={(text, client: Client) => (
                  <div className="action-btns">
                    <div>
                      <Button type="primary" onClick={() => setClientFormSource(client)}>
                        Edit
                      </Button>
                    </div>
                    <div>
                      <StatusToggleButton
                        currentStatus={client.active}
                        onSubmit={(active: boolean) =>
                          dispatch(clientUpdate({ id: client.id, active } as Client))
                        }
                      />
                    </div>
                  </div>
                )}
              />
            </Table>
            {clientFormSource && (
              <ClientFormModal
                client={clientFormSource as Partial<Client>}
                onClose={() => {
                  setClientFormSource(undefined);
                  resetBodyOverflow();
                }}
              />
            )}
          </>
        )}
      </Col>
    </Row>
  );
};
