import React, { useCallback, useState } from "react";
import { Breadcrumb, Button, Col, Table, PageHeader, Row, Input, Tag } from "antd";
import { useHistory, Switch, Route, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pricingListSelectors } from "../../store/pricingLists/pricingListSlice";
import { PricingList } from "../../interfaces/PricingList";
import { AppDispatch } from "../../store/store";
import { PricingListFormModal } from "../../components/pricing_list_form/PricingListFormModal";
import { queryToRegex, resetBodyOverflow } from "../../utils";
import { PricingListView } from "./PricingListView";
import { debounce } from "lodash";
import { collectionSelectors } from "../../store/collectionSlice/collectionsSlice";
import { clientSelectors } from "../../store/clientsSlice/clientsSlice";
import { CompanyIssues } from "./CompanyIssues";
import { TaxExemption } from "./TaxExemption";

const filterPricingLists = (pricingLists: PricingList[] | undefined, query: string) => {
  if (!query || query.length < 2) {
    return pricingLists;
  }
  const searchQuery = queryToRegex(query);
  return (pricingLists || []).filter((p) => p.title.match(searchQuery));
};

export const PricingLists = () => {
  const history = useHistory();
  let { path } = useRouteMatch();

  const dispatch: AppDispatch = useDispatch();
  const pricingLists = useSelector(pricingListSelectors.selectAll);
  const collections = useSelector(collectionSelectors.selectAll);
  const clients = useSelector(clientSelectors.selectAll);

  const getCollection = (pricingList: PricingList) => {
    const client = clients.find((c) => c.pricingListId === pricingList.id);
    if (client) {
      return collections.find((c) => c.id === client.collectionId);
    }
  };

  const [pricingListModalSource, setPricingListModalSource] = useState<Partial<PricingList>>();

  const [searchQuery, setSearchQuery] = useState("");

  const searchWithFilter = useCallback(
    debounce((query: string) => setSearchQuery(query), 300),
    [searchQuery]
  );

  return (
    <Row className="page pricing-lists">
      <Switch>
        <Route exact path={path}>
          <Breadcrumb>
            <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item key="clients">Pricing Lists</Breadcrumb.Item>
          </Breadcrumb>
          <Col sm={24}>
            <PageHeader
              title="Pricing Lists"
              className="site-page-header"
              onBack={() => history.push("/")}
              extra={[
                <Row>
                  <Col sm={16}>
                    <Input
                      addonBefore="search"
                      onChange={(e) => {
                        e.persist();
                        searchWithFilter(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={8} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      key="new-pricing-list"
                      onClick={() => setPricingListModalSource({})}
                    >
                      Add Pricing List
                    </Button>
                  </Col>
                </Row>,
              ]}
            />
          </Col>
          <Col sm={24}>
            <Table dataSource={filterPricingLists(pricingLists, searchQuery)} rowKey="id">
              <Table.Column title="Pricing List No" dataIndex="id" key="id" />
              <Table.Column title="Price List Name" dataIndex="title" key="title" />
              <Table.Column
                title="Collection"
                render={(pl) => {
                  const collectionName = getCollection(pl)?.title;
                  return collectionName ? (
                    <Tag key={collectionName || ""}>{collectionName}</Tag>
                  ) : (
                    ""
                  );
                }}
              />
              <Table.Column
                title="Actions"
                render={(text, pricingList: PricingList) => (
                  <div className="action-btns">
                    <div>
                      <Button
                        type="primary"
                        onClick={() => history.push("/pricingLists/" + pricingList.id)}
                      >
                        View
                      </Button>
                    </div>
                    <div>
                      <Button type="primary" onClick={() => setPricingListModalSource(pricingList)}>
                        Edit
                      </Button>
                    </div>
                  </div>
                )}
              />
            </Table>
          </Col>
          {pricingListModalSource && (
            <PricingListFormModal
              onClose={() => {
                setPricingListModalSource(undefined);
                resetBodyOverflow();
              }}
              source={pricingListModalSource}
            />
          )}{" "}
        </Route>
        <Route path={`${path}/:pricingListId/company_issues`} component={CompanyIssues} />
        <Route path={`${path}/:pricingListId/tax_exemption`} component={TaxExemption} />
        <Route path={`${path}/:id`} component={PricingListView} />
      </Switch>
    </Row>
  );
};
