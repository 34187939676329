import React, { useState } from "react";
import { Button, Card, Table } from "antd";
import { Variant } from "../../interfaces/Variant";
import { StatusToggleButton } from "../status_toggle_button/StatusToggleButton";
import { Product } from "../../interfaces/Product";
import { PricingList } from "../../interfaces/PricingList";
import { useAppDispatch } from "../../store/store";
import { pricingListVariantUpdate } from "../../store/pricingLists/pricingListSlice";
import { DiscountedPrice } from "../../interfaces/DiscountedPrice";
import {
  DiscountedPriceFormModal,
  DiscountedPriceFormModalSource,
} from "../discounted_price_form_modal/DiscountedPriceFormModal";
import { resetBodyOverflow } from "../../utils";

interface IProps {
  product: Product;
  pricingList: PricingList;
}

export const PricingListPanel = ({ pricingList, product }: IProps) => {
  const dispatch = useAppDispatch();

  const [discountedPriceFormModalSource, setDiscountedPriceFormModalSource] = useState<
    DiscountedPriceFormModalSource
  >();

  return (
    <Card title="Product Variants">
      <Table dataSource={product.variants} rowKey="id" pagination={false}>
        <Table.Column title="Variant" dataIndex="title" key="title" />
        <Table.Column title="Variant ID" dataIndex="id" key="id" />
        <Table.Column title="SKU" dataIndex="sku" key="sku" />
        <Table.Column title="Variant Price" dataIndex="price" key="price" render={(v) => `$${v}`} />
        <Table.Column
          title="Discounted Price"
          render={(text, variant: Variant) =>
            `$${pricingList.discountedPrices.find((dp) => dp.variantId === variant.id)?.price}`
          }
        />
        <Table.Column
          title="Actions"
          render={(text, variant: Variant) => (
            <div className="action-btns">
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    const dp = pricingList.discountedPrices.find(
                      (dp) => dp.variantId === variant.id
                    ) as DiscountedPrice;
                    setDiscountedPriceFormModalSource({
                      variantId: dp.variantId,
                      pricingListId: dp.pricingListId,
                      price: dp.price,
                      id: dp.id,
                    } as DiscountedPriceFormModalSource);
                  }}
                >
                  Edit Discounted Price
                </Button>
              </div>
              <div>
                <StatusToggleButton
                  currentStatus={
                    !!pricingList.discountedPrices.find((dp) => dp.variantId === variant.id)?.active
                  }
                  onSubmit={(active: boolean) => {
                    const dp = pricingList.discountedPrices.find(
                      (dp) => dp.variantId === variant.id
                    ) as DiscountedPrice;
                    dispatch(
                      pricingListVariantUpdate({
                        variantId: dp.variantId,
                        pricingListId: dp.pricingListId,
                        active,
                      } as DiscountedPrice)
                    );
                  }}
                />
              </div>
            </div>
          )}
        />
      </Table>
      {discountedPriceFormModalSource && (
        <DiscountedPriceFormModal
          discountedPrice={discountedPriceFormModalSource}
          onClose={() => {
            setDiscountedPriceFormModalSource(undefined);
            resetBodyOverflow();
          }}
        />
      )}
    </Card>
  );
};
