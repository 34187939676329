import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { userReducer } from "./user/userSlice";
import { User } from "../interfaces/User";
import { shopReducers } from "./shop/shopSlice";
import { Shop } from "../interfaces/Shop";
import { collectionsReducers } from "./collectionSlice/collectionsSlice";
import { Collection } from "../interfaces/Collection";
import { pricingListsReducers } from "./pricingLists/pricingListSlice";
import { useDispatch } from "react-redux";
import { clientsSlice } from "./clientsSlice/clientsSlice";
import { ordersSlice } from "./ordersSlice/ordersSlice";
import { ordersPaginationSlice } from "./ordersSlice/ordersPaginationSlice";
import { promotionsPaginationSlice, promotionsSlice } from "./promotionsSlice";
import { inventoryLocationsSlice } from "./inventoryLocationsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    shop: shopReducers,
    clients: clientsSlice.reducer,
    collections: collectionsReducers,
    pricingLists: pricingListsReducers,
    orders: ordersSlice.reducer,
    ordersPagination: ordersPaginationSlice.reducer,
    promotions: promotionsSlice.reducer,
    promotionsPagination: promotionsPaginationSlice.reducer,
    inventoryLocations: inventoryLocationsSlice.reducer,
  },
});

interface PartialState {
  user: User;
  shop: Shop;
}
export type RootState = ReturnType<typeof store.getState> & PartialState;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
