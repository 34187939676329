import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../interfaces/Client";
import { api, extractStandardResponseData } from "../../api";
import { endpoints } from "../../api/endpoints";
import { RootState } from "../store";

export const clientAdapter = createEntityAdapter<Client>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => (a.id > b.id ? 1 : 0),
});

export const clientsFetchAll = createAsyncThunk("clients/clientsFetchAll", async () =>
  api.get(endpoints.clients).then(extractStandardResponseData)
);

export const clientCreate = createAsyncThunk(
  "clients/clientCreate",
  async (client: Partial<Client>) =>
    api.post(endpoints.clientCreate, client).then(extractStandardResponseData)
);

export const clientUpdate = createAsyncThunk("clients/clientUpdate", async (client: Client) =>
  api.post(endpoints.clientUpdate(client.id), client).then(extractStandardResponseData)
);

export const clientsSlice = createSlice({
  name: "Clients",
  initialState: clientAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clientsFetchAll.fulfilled, clientAdapter.setAll);
    builder.addCase(clientCreate.fulfilled, clientAdapter.addOne);
    builder.addCase(clientUpdate.fulfilled, clientAdapter.upsertOne);
  },
});

export const clientSelectors = clientAdapter.getSelectors((state: RootState) => state.clients);
