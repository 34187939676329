import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { PricingList } from "../../interfaces/PricingList";
import { endpoints } from "../../api/endpoints";
import { api, extractStandardResponseData } from "../../api";
import { RootState } from "../store";
import { DiscountedPrice } from "../../interfaces/DiscountedPrice";
import { DefaultDeserializer } from "v8";
import { DefaultExemptState } from "../../interfaces/DefaultExemptState";
const reactUsStates = require("react-us-states")

export const pricingListAdapter = createEntityAdapter<PricingList>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => (a.id > b.id ? 1 : 0),
});

export const fetchPricingLists = createAsyncThunk("pricingLists/fetchPricingLists", async () => {
  const {
    data: { data },
  } = await api.get(endpoints.pricingLists);
  return data;
});

export const pricingListUpdate = createAsyncThunk(
  "pricingLists/pricingListUpdate",
  async (pl: PricingList) =>
    api.patch(endpoints.pricingListsUpdate(pl.id), pl).then(extractStandardResponseData)
);

export const pricingListCreate = createAsyncThunk(
  "pricingLists/pricingListCreate",
  async (pl: Partial<PricingList>) =>
    api.post(endpoints.pricingListsCreate, pl).then(extractStandardResponseData)
);

export const pricingListGet = createAsyncThunk("pricingLists/pricingListGet", async (id: number) =>
  api.get(endpoints.pricingListsGet(id)).then(extractStandardResponseData)
);

export const pricingListVariantUpdate = createAsyncThunk(
  "pricingLists/pricingListVariantUpdate",
  async (dp: DiscountedPrice) =>
    api.patch(endpoints.pricingListsVariantUpdate(dp), dp).then(extractStandardResponseData)
);

export const pricingListProductUpdate = createAsyncThunk(
  "pricingLists/pricingListProductUpdate",
  async (dp: DiscountedPrice) =>
    api.patch(endpoints.pricingListsProductUpdate(dp), dp).then(extractStandardResponseData)
);

export const getDefaultCompanyIssues = createAsyncThunk(
  "pricingLists/getDefaultCompanyIssues",
  async (pricingListId: number) => {
    const {
      data: { data },
    } = await api.get(`/pricingLists/${pricingListId}/company_issues`);
    // console.log(data)
    return data;
  }
);

export const getDefaultExemptStates = createAsyncThunk(
  "pricingLists/getDefaultExemptStates",
  async (pricingListId: number) => {
    const {
      data: { data },
    } = await api.get(`/taxExemption/${pricingListId}/tax_exemption_states`);
    console.log(data.length)
    // {data: [{id:1,pricingListId:1,name:"alabama",isExempt:false}]}
    if(!data.length){
      console.log("Using default states")
      const states = reactUsStates.useStates()
      const defaultStatesExemptions: DefaultExemptState[] = [];
      states.forEach((state: { name: any; }) => {
        defaultStatesExemptions.push({id:1,stateNameOrCode:state.name,isExempt:false})
      });
      console.log(defaultStatesExemptions)
      return  defaultStatesExemptions;
    }
    else{
      const states = data
      const defaultStatesExemptions: DefaultExemptState[] = [];
      states.forEach((state: { stateNameOrCode: string, isExempt: boolean },index:number) => {
        defaultStatesExemptions.push({id:index,stateNameOrCode:state.stateNameOrCode,isExempt:state.isExempt})
      });
      console.log(defaultStatesExemptions)
      return  defaultStatesExemptions;
    }
  }
);

export const getDefaultExemptStatus = createAsyncThunk(
  "pricingLists/getDefaultExemptStatus",
  async (pricingListId: number) => {
    const {
      data: { data },
    } = await api.get(`/taxExemption/${pricingListId}/tax_exemption_status`);
    // console.log({data})
    // {data: [{id:1,pricingListId:1,name:"alabama",isExempt:false}]}
    return data
  }
);

export const clientUpdateTaxExemption = createAsyncThunk(
  "pricingLists/getDefaultExemptStates", 
  async (state:boolean) => state
  // api.post(endpoints.clientUpdate(client.id), client).then(extractStandardResponseData)

);

export const updateDefaultCompanyIssues = createAsyncThunk(
  "pricingLists/updateDefaultCompanyIssues",
  async (payload: { pricingListId: number; data: any }) => {
    const {
      data: { data },
    } = await api.patch(`/pricingLists/${payload.pricingListId}/company_issues`, payload.data);
    return data;
  }
);

export const updateDefaultTaxExemption = createAsyncThunk(
  "pricingLists/updateDefaultTaxExemption",
  async (payload: { pricingListId: number; data: any; isTaxExempt: boolean }) => {
    console.log({payload})
    const {
      data: { data },
    } = await api.patch(`/taxExemption/${payload.pricingListId}/tax_exemption`, payload);
    return data;
  }
);

export const addProductToDefaultCompanyIssues = createAsyncThunk(
  "pricingLists/addProductToDefaultCompanyIssues",
  async (payload: { pricingListId: number; productId: number }) => {
    const {
      data: { data },
    } = await api.patch(
      `/pricingLists/${payload.pricingListId}/company_issues/products/${payload.productId}`
    );
    return data;
  }
);

export const removeProductFromDefaultCompanyIssues = createAsyncThunk(
  "pricingLists/removeProductFromDefaultCompanyIssues",
  async (payload: { pricingListId: number; productId: number }) => {
    const {
      data: { data },
    } = await api.delete(
      `/pricingLists/${payload.pricingListId}/company_issues/products/${payload.productId}`
    );
    return data;
  }
);

export const resetDefaultCompanyIssues = createAsyncThunk(
  "pricingLists/resetDefaultCompanyIssues",
  async (pricingListId: number) => {
    const {
      data: { data },
    } = await api.post(`/pricingLists/${pricingListId}/company_issues/reset`);
    return data;
  }
);

export const resetDefaultTaxExemption = createAsyncThunk(
  "pricingLists/resetDefaultCompanyIssues",
  async (pricingListId: number) => {
    const {
      data: { data },
    } = await api.post(`/taxExemption/${pricingListId}/tax_exemption/reset`);
    return data;
  }
);

const pricingListsSlice = createSlice({
  name: "pricingLists",
  initialState: pricingListAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricingLists.fulfilled, pricingListAdapter.setAll)
      .addCase(pricingListUpdate.fulfilled, pricingListAdapter.upsertOne)
      .addCase(pricingListCreate.fulfilled, pricingListAdapter.addOne)
      .addCase(pricingListGet.fulfilled, pricingListAdapter.upsertOne)
      .addCase(pricingListVariantUpdate.fulfilled, pricingListAdapter.upsertOne)
      .addCase(pricingListProductUpdate.fulfilled, pricingListAdapter.upsertOne);
  },
});

export const pricingListsActions = pricingListsSlice.actions;
export const pricingListsReducers = pricingListsSlice.reducer;
export const pricingListSelectors = pricingListAdapter.getSelectors(
  (s: RootState) => s.pricingLists
);
