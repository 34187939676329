import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { InventoryLocation } from "../interfaces/InventoryLocation";
import { RootState } from "./store";
import { api, extractStandardResponseData } from "../api";

export const inventoryLocationAdapter = createEntityAdapter<InventoryLocation>();

export const getInventoryLocations = createAsyncThunk(
  "inventoryLocations/getInventoryLocations",
  () => api.get("/inventories/locations").then(extractStandardResponseData)
);

export const inventoryLocationsSlice = createSlice({
  name: "inventoryLocation",
  initialState: inventoryLocationAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInventoryLocations.fulfilled, inventoryLocationAdapter.addMany);
  },
});

export const inventoryLocationSelector = inventoryLocationAdapter.getSelectors(
  (s: RootState) => s.inventoryLocations
);
