import { Order } from "../../interfaces/Order";
import { VerificationStatus } from "../../enums/VerificationStatus";
import { Button, Space, Table, Tag } from "antd";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Customer } from "../../interfaces/Customer";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

const verificationStatusTagColors = {
  [VerificationStatus.PENDING]: "#108ee9",
  [VerificationStatus.APPROVED]: "#87d068",
  [VerificationStatus.REJECTED]: "#f50",
};

export const OrdersTable = ({ orders, loading }: { orders: Order[]; loading?: boolean }) => {
  const history = useHistory();

  return (
    <>
      <Table
        dataSource={orders}
        rowKey="id"
        pagination={false}
        loading={loading}
        scroll={{ x: 1300 }}
      >
        <Table.Column
          title="Order"
          dataIndex="name"
          fixed="left"
          width={140}
          render={(name, order: any) => <Link to={`/orders/${order.id}`}> {name}</Link>}
        />
        <Table.Column
          title="Verification Status"
          dataIndex="verificationStatus"
          key="verificationStatus"
          fixed="left"
          width={170}
          render={(status: VerificationStatus) => (
            <Tag color={verificationStatusTagColors[status]}>{status.toUpperCase()}</Tag>
          )}
        />
        <Table.Column
          title="Financial Status"
          dataIndex="financialStatus"
          key="financialStatus"
          width={180}
          render={(s) => <Tag> {_.capitalize(s?.replace("_", " "))}</Tag>}
        />
        <Table.Column
          title="Fulfillment Status"
          dataIndex="fulfillmentStatus"
          key="fulfillmentStatus"
          width={180}
          render={(s) => (
            <Tag color={s === "fulfilled" ? "" : "rgb(255, 215, 157)"} style={{ color: "black" }}>
              {_.capitalize(s?.replace("_", " "))}
            </Tag>
          )}
        />
        <Table.Column
          title="Verified At"
          dataIndex="verifiedAt"
          key="verifiedAt"
          width={180}
          render={(date) => date && DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)}
        />
        <Table.Column
          title="Verification Note"
          dataIndex="verificationNote"
          key="verificationNote"
          width={350}
        />
        <Table.Column
          title="Actions"
          render={(text, order: Order) => (
            <Space direction="horizontal">
              <Button type="primary" onClick={() => history.push(`/orders/${order.id}`)}>
                View
              </Button>
              <Button
                type="primary"
                href={`${process.env.REACT_APP_SHOP_URL}/admin/orders/${order.id}`}
                target="_blank"
              >
                View In Shopify
              </Button>
            </Space>
          )}
        />
      </Table>
    </>
  );
};
