import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Breadcrumb, Button, Col, PageHeader, Row } from "antd";
import React from "react";
import { PromotionForm } from "./PromotionForm";

export const NewPromotion = () => {
  const { path, params } = useRouteMatch();
  const history = useHistory();
  return (
    <Row className="page promotions">
      <Switch>
        <Route exact path={path}>
          <Breadcrumb>
            <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item key="promotions">Discounts</Breadcrumb.Item>
            <Breadcrumb.Item key="new">New</Breadcrumb.Item>
          </Breadcrumb>
          <Col sm={24}>
            <PageHeader
              title="New Discount"
              className="site-page-header"
              onBack={() => history.push("/discounts")}
              extra={
                [
                  // <Button
                  //   type="primary"
                  //   key="add-discount-btn"
                  //   onClick={() => history.push("/discounts/new")}
                  // >
                  //   Add Discount
                  // </Button>,
                ]
              }
            />
          </Col>
          <Col sm={24}>
            <PromotionForm />
          </Col>
        </Route>
      </Switch>
    </Row>
  );
};
