import React, { useState } from "react";
import { Product } from "../../interfaces/Product";
import { PricingList } from "../../interfaces/PricingList";
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { StatusToggleButton } from "../status_toggle_button/StatusToggleButton";
import { DiscountedPrice } from "../../interfaces/DiscountedPrice";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import {
  DiscountedPriceFormModal,
  DiscountedPriceFormModalSource,
} from "../discounted_price_form_modal/DiscountedPriceFormModal";
import { resetBodyOverflow } from "../../utils";
import {
  addProductToDefaultCompanyIssues,
  fetchPricingLists,
  pricingListGet,
  pricingListProductUpdate,
  pricingListSelectors,
  removeProductFromDefaultCompanyIssues,
} from "../../store/pricingLists/pricingListSlice";
import { Collection } from "../../interfaces/Collection";
import { log } from "util";
import { unwrapResult } from "@reduxjs/toolkit";
import { message } from "antd/es";

interface IProps {
  product: Product;
  pricingListId: number;
  collection: Collection;
}

export const PricingListHeader = ({ pricingListId, product, collection }: IProps) => {
  const dispatch = useAppDispatch();

  const pricingList = useSelector((state: RootState) =>
    pricingListSelectors.selectById(state, pricingListId)
  ) as PricingList;

  const discountedPrices = useSelector((state: RootState) =>
    pricingListSelectors
      .selectById(state, pricingList.id)
      ?.discountedPrices.filter((dp) => dp.productId === product.id)
  );

  const discountedPrice = pricingList.discountedPrices.find((dp) => dp.productId === product.id);

  const [discountedPriceFormModalSource, setDiscountedPriceFormModalSource] = useState<
    DiscountedPriceFormModalSource
  >();

  const onCompanyIssueProductCheckboxChange = async (e: any) => {
    await dispatch(
      (e.target.checked ? addProductToDefaultCompanyIssues : removeProductFromDefaultCompanyIssues)(
        {
          pricingListId,
          productId: product.id,
        }
      )
    ).then(unwrapResult);
    dispatch(pricingListGet(pricingListId));
    message.success(
      `Product ${e.target.checked ? "added" : "removed"} from default company issues`
    );
  };

  return (
    <div>
      <PageHeader title={product.title} />
      <Row>
        <Col sm={16}>
          <Descriptions column={3}>
            <Descriptions.Item label="Shopify Status">
              {product.status ? "Active" : "Inactive"}
            </Descriptions.Item>
            <Descriptions.Item label="Base Price">
              {"$" + product.variants[0].price}
            </Descriptions.Item>
            <Descriptions.Item label="Discounted Price">
              {"$" + discountedPrice?.price}
            </Descriptions.Item>
            <Descriptions.Item label="Product ID">{product.id}</Descriptions.Item>
            <Descriptions.Item label="Variant SKUs" span={3}>
              {Array.from(
                new Set(product.variants.map((v) => v.sku).filter((sku) => sku?.length))
              ).map((s) => (
                <Tag color="processing" key={s}>
                  {s}
                </Tag>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col sm={8} style={{ textAlign: "center" }}>
          <Space direction="vertical" align="start" size="large">
            <Space direction="horizontal">
              <Button
                type="primary"
                onClick={() => {
                  if (discountedPrices) {
                    setDiscountedPriceFormModalSource(discountedPrices[0]);
                  }
                }}
              >
                Edit Discounted Price
              </Button>
              <StatusToggleButton
                currentStatus={(discountedPrices || []).some((dp) => dp.active)}
                onSubmit={(active: boolean) =>
                  dispatch(
                    pricingListProductUpdate({
                      pricingListId: pricingList.id,
                      productId: product.id,
                      active,
                    } as DiscountedPrice)
                  )
                }
              />
            </Space>
            <div>
              <Checkbox
                checked={
                  !!pricingList.defaultCompanyIssueProduct.find((i) => i.productId === product.id)
                }
                onChange={onCompanyIssueProductCheckboxChange}
              />
              <Typography.Text style={{ paddingLeft: 10 }}>Company Issue</Typography.Text>
            </div>
          </Space>
        </Col>
      </Row>
      {discountedPriceFormModalSource && (
        <DiscountedPriceFormModal
          discountedPrice={discountedPriceFormModalSource}
          onClose={() => {
            setDiscountedPriceFormModalSource(undefined);
            resetBodyOverflow();
          }}
        />
      )}
    </div>
  );
};
