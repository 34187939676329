import React, { useEffect, useState } from "react";
import "./App.scss";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { Login } from "./pages/login/Login";
import { RootState } from "./store/store";
import { useDispatch, useSelector } from "react-redux";
import { Clients } from "./pages/Clients/Clients";
import { setAxiosToken } from "./api";
import { DefaultLayout } from "./components/default_layout/DefaultLayout";
import { localStorageApi } from "./api/localStorage";
import { refreshUser } from "./store/user/userSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { PricingLists } from "./pages/pricingLists/PricingLists";
import { getShop, resetShopState } from "./store/shop/shopSlice";
import { Orders } from "./pages/Orders/Orders";
import { Customers } from "./pages/Customers/Customers";
import { Promotions } from "./pages/Promotions";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://858eefd958814146a503f8cfa0220c68@sentry.mydevops.org/7",
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
  });
}

function App() {
  const user = useSelector((state: RootState) => state.user);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isLoggedIn = !!user?.id && !!user?.token;
    setIsAuthenticated(isLoggedIn);
    if (isLoggedIn) {
      Sentry.setUser({ email: user?.email });
    }
    // @ts-ignore
    setLoading(user?.loginLoading);

    if (isLoggedIn) {
      dispatch(getShop());
      dispatch(resetShopState());
    }
  }, [user]);

  useEffect(() => {
    const token = localStorageApi.getToken();
    if (token) {
      setAxiosToken(token);
      dispatch(refreshUser(token));
    } else setLoading(false);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="d-center" style={{ height: "100vh" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <BrowserRouter>
          <Switch>
            <Route exact path="/login">
              <Login isLoggedIn={isAuthenticated} />
            </Route>
            <PrivateRoute path="/clients" isLoggedIn={isAuthenticated}>
              <Clients />
            </PrivateRoute>
            <PrivateRoute path="/pricingLists" isLoggedIn={isAuthenticated}>
              <PricingLists />
            </PrivateRoute>
            <PrivateRoute path="/orders" isLoggedIn={isAuthenticated}>
              <Orders />
            </PrivateRoute>
            <PrivateRoute path="/customers" isLoggedIn={isAuthenticated}>
              <Customers />
            </PrivateRoute>
            <PrivateRoute path="/discounts" isLoggedIn={isAuthenticated}>
              <Promotions />
            </PrivateRoute>
            <Route exact path="/">
              <Redirect to="/clients" />
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
}

const PrivateRoute = ({ children, isLoggedIn, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn === true ? (
          <DefaultLayout>{children}</DefaultLayout>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default App;
