import React from "react";
import { Refund } from "../../../interfaces/Refund";
import { Card, Col, Descriptions, Row, Space, Table, Typography } from "antd";
import { centsToDollars, parseLabel } from "../../../utils";
import moment from "moment";
import { Money } from "../../../components/Money";

interface IProps {
  refund: Refund;
}

export const RefundCard = ({ refund }: IProps) => {
  return (
    <Card title={`#Refunded on ${moment(refund.createdAt).format("MM-DD-yyyy hh:mm:ss")}`}>
      <Row>
        <Col sm={16}>
          <Space direction="vertical">
            <Descriptions column={2} bordered>
              <Descriptions.Item label="Status">
                {refund.syncedSucceeded
                  ? "Success"
                  : refund.syncedSucceeded === false
                  ? "Failed"
                  : "In-process"}
              </Descriptions.Item>
              <Descriptions.Item label="Created on">
                {moment(refund.createdAt).format("MM-DD-YYYY hh:mm:ss")}
              </Descriptions.Item>
              <Descriptions.Item label="Synced with Shopify At">
                {refund.syncedWithShopifyAt
                  ? moment(refund.syncedWithShopifyAt).format("MM-DD-YYYY hh:mm:ss")
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Customer notified at">
                {refund.customerNotifiedAt
                  ? moment(refund.customerNotifiedAt).format("MM-DD-YYYY hh:mm:ss")
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Shipping amount">
                <Money value={refund.shippingAmount || 0} />
              </Descriptions.Item>
            </Descriptions>
            <Typography.Title level={5}>Line Items</Typography.Title>
            <Table dataSource={refund.refundLineItems} rowKey={"id"} pagination={false}>
              <Table.Column
                title="Product"
                render={(item) => item.lineItem.variant.product.title}
              />
              <Table.Column title="Variant" render={(item) => item.lineItem.variant.title} />
              <Table.Column title="Quantity" render={(item) => item.quantity} />
            </Table>

            {refund.errorResponse ? (
              <Card title="Error response">{JSON.stringify(refund.errorResponse)}</Card>
            ) : null}
          </Space>
        </Col>
        <Col sm={8}>
          <Card title="Transactions">
            <Table dataSource={refund.transactions} pagination={false}>
              <Table.Column
                title="Account"
                dataIndex="accountType"
                render={(accountType) => parseLabel(accountType)}
              />
              <Table.Column
                title="Amount"
                dataIndex="amount"
                render={(amt) => <Money value={amt} />}
              />
            </Table>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
