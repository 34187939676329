import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Breadcrumb, Button, Col, PageHeader, Pagination, Row, Table, Tag } from "antd";
import { PricingListView } from "../pricingLists/PricingListView";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import { ordersIndex } from "../../store/ordersSlice/ordersSlice";
import { useSelector } from "react-redux";
import { Order } from "../../interfaces/Order";
import { VerificationStatus } from "../../enums/VerificationStatus";
import { ordersPaginationSelectors } from "../../store/ordersSlice/ordersPaginationSlice";
import { DateTime } from "luxon";
import { CustomerSearch } from "./CustomerSearch";

export const Customers = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const total = useSelector(ordersPaginationSelectors.total);
  const availablePages = useSelector(ordersPaginationSelectors.pages.selectIds);

  const orders = useSelector((state: RootState) =>
    ordersPaginationSelectors.get(state, currentPage)
  ) as Order[];

  useEffect(() => {
    if (availablePages && !loading && !availablePages.includes(currentPage)) {
      setLoading(true);
      dispatch(ordersIndex({ page: currentPage })).then(() => setLoading(false));
    }
  }, [currentPage, orders]);

  return (
    <Row className="page customers">
      <Switch>
        <Route exact path={path} key="customers-route">
          Hello world
        </Route>
        <Route path={`${path}/search`} component={CustomerSearch} key="customer-search-route" />
      </Switch>
    </Row>
  );
};
