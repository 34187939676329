import { DiscountedPrice } from "../interfaces/DiscountedPrice";

export const endpoints = {
  login: "/login",
  refreshUser: "/user",
  shop: "/shops",
  syncShop: "/shops/sync",
  clients: "/clients",
  clientCreate: "/clients",
  clientUpdate: (id: number) => `/clients/${id}`,
  collections: "/collections",
  pricingLists: "/pricingLists",
  pricingListsGet: (id: number) => `/pricingLists/${id}`,
  pricingListsCreate: "/pricingLists",
  pricingListsUpdate: (id: number) => `/pricingLists/${id}`,
  pricingListsVariantUpdate: ({ pricingListId, variantId }: DiscountedPrice) =>
    `/pricingLists/${pricingListId}/variants/${variantId}`,
  pricingListsProductUpdate: ({ pricingListId, productId }: DiscountedPrice) =>
    `/pricingLists/${pricingListId}/products/${productId}`,
  products: "/shops/products",
  ordersIndex: "/orders",
  customersSearchWithTag: `/customers/find_with_tag`,
  customersSearch: `/customers/search`,
};
