import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Page } from "../../interfaces/Page";
import { ordersIndex, ordersSelector } from "./ordersSlice";
import { Order } from "../../interfaces/Order";
import { RootState } from "../store";

const ordersPaginationAdapter = createEntityAdapter<Page>();

export const clearPaginatedOrders = createAsyncThunk("orders/clearPaginatedOrders", () =>
  Promise.resolve()
);

export const ordersPaginationSlice = createSlice({
  name: "ordersPagination",
  initialState: {
    pages: ordersPaginationAdapter.getInitialState(),
    total: 0,
    filters: { searchTerm: "" },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ordersIndex.fulfilled, (state, { payload }) => {
      return {
        pages: ordersPaginationAdapter.upsertOne(
          { ...state.pages },
          { id: payload.page, items: payload.data.map((o: Order) => o.id) }
        ),
        total: payload.total,
        filters: payload.filters || {},
      };
    });
    builder.addCase(clearPaginatedOrders.fulfilled, (state, action) => ({
      pages: ordersPaginationAdapter.getInitialState(),
      total: 0,
      filters: { searchTerm: "" },
    }));
  },
});

const adapterSelector = ordersPaginationAdapter.getSelectors(
  (s: RootState) => s.ordersPagination.pages
);

const empty: Order[] = [];

export const ordersPaginationSelectors = {
  pages: adapterSelector,
  get: (state: RootState, page: number) => {
    return (
      adapterSelector
        .selectById(state, page)
        ?.items?.map((id) => ordersSelector.selectById(state, id)) || empty
    );
  },
  total: (s: RootState) => s.ordersPagination.total,
};
