import { useHistory } from "react-router-dom";
import { Breadcrumb, Button, Col, Collapse, Descriptions, Form, Modal, PageHeader, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../store/store";
import Search from "antd/es/input/Search";
import { searchCustomer, searchCustomerWithTag, deleteDeletedCustomer } from "../../store/customersSlice/customersSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Customer } from "../../interfaces/Customer";
import DescriptionsItem from "antd/es/descriptions/Item";
import { OrdersTable } from "../Orders/OrdersTable";
import { Money } from "../../components/Money";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const CustomerSearch = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>();

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const fetchCustomerList = async () => {
    setLoading(true);
    const result = await dispatch(searchCustomer("")).then(unwrapResult);
    setCustomers(result);
    setLoading(false);
  };

  const onSearch = useCallback(async (query: string) => {
    if (query.length < 3) return;
    setSearchTerm(query);
    setLoading(true);
    const result = await dispatch(searchCustomer(query)).then(unwrapResult);
    setCustomers(result);
    setErrorMessage(!result.length ? `No match found for: ${query}` : undefined);
    setLoading(false);
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item key="customers">Customers</Breadcrumb.Item>
        <Breadcrumb.Item key="search">Search</Breadcrumb.Item>
      </Breadcrumb>
      <Col sm={24}>
        <PageHeader
          title="Customer Search"
          className="site-page-header"
          onBack={() => history.push("/")}
          extra={[]}
        />
      </Col>
      <Col sm={24}>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
          <Form style={{ width: 500 }}>
            <Search placeholder="input search text" onSearch={onSearch} enterButton size="large" />
          </Form>
        </div>
        {errorMessage && <h3>{errorMessage}</h3>}

        {!!customers.length && (
          <Collapse style={{ textAlign: "left" }} accordion>
            {(customers || []).map((customer) => (
              <>
                <Collapse.Panel
                  key={customer.id}
                  showArrow={false}
                  header={
                    <div>
                      <Descriptions title={`${customer.firstName} ${customer.lastName}`} column={3}>
                        <DescriptionsItem label="Email" key="email">
                          <Tag color={"blue"}>{customer.email}</Tag>
                        </DescriptionsItem>
                        <DescriptionsItem label="Allotment balance" key="allotmentBalance">
                          <Money value={customer.allotmentBalance} />
                        </DescriptionsItem>
                        <DescriptionsItem label="Payroll balance" key="payrollBalance">
                          <Money value={customer.payrollBalance} />
                        </DescriptionsItem>
                        <DescriptionsItem label="Payroll Minimum " key="payrollMinimum">
                          <Money value={customer.minimumPayrollTransactionAmount} />
                        </DescriptionsItem>
                        <DescriptionsItem label="Tags" key="tags">
                          {customer.tags.map((t) => (
                            <Tag>{t.value}</Tag>
                          ))}
                        </DescriptionsItem>
                        <DescriptionsItem>
                          <Button
                            type={"primary"}
                            href={`${process.env.REACT_APP_SHOP_URL}/admin/customers/${customer.id}`}
                            target="_blank"
                          >
                            View in Shopify
                          </Button>
                        </DescriptionsItem>
                        <DescriptionsItem>
                          <Button
                            onClick={() =>
                              Modal.confirm({
                                title: "Are you sure you want to delete this discount?",
                                icon: <ExclamationCircleOutlined />,
                                onOk: () => dispatch(deleteDeletedCustomer(customer)),
                                okText: "Delete",
                              })
                            }
                            danger
                          >
                            Delete
                          </Button>
                        </DescriptionsItem>
                      </Descriptions>
                      {/* <div>
                          <Button
                            type={"primary"}
                            href={`${process.env.REACT_APP_SHOP_URL}/admin/customers/${customer.email}`}
                            target="_blank"
                          >
                            Delete
                          </Button>
                      </div> */}
                    </div>
                  }
                >
                  <div>
                    <h3>Orders</h3>
                    {<OrdersTable orders={customer.orders} />}
                  </div>
                </Collapse.Panel>
              </>
            ))}
          </Collapse>
        )}
      </Col>
    </>
  );
};
