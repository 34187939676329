import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  Tag,
} from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  calculateRefundTransactions,
  createRefund,
  ordersSelector,
  refundAvailability,
} from "../../../store/ordersSlice/ordersSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "../../../store/store";
import { Refund } from "../../../interfaces/Refund";
import { useSelector } from "react-redux";
import { Transaction } from "../../../interfaces/Transaction";
import { Order } from "../../../interfaces/Order";
import { centsToDollars, dollarsToCents, formatMoney } from "../../../utils";
import { RefundLineItem } from "../../../interfaces/RefundLineItem";
import { clearPaginatedOrders } from "../../../store/ordersSlice/ordersPaginationSlice";
import {
  getInventoryLocations,
  inventoryLocationSelector,
} from "../../../store/inventoryLocationsSlice";
import { Money } from "../../../components/Money";
import TextArea from "antd/es/input/TextArea";

export const NewRefund = () => {
  const {
    params: { id: orderId },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [availableRefunds, setAvailableRefunds] = useState<Partial<Refund>>();
  const [refunds, setRefunds] = useState<Partial<Refund>>({});
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [maxRefundable, setMaxRefundable] = useState<any>({});
  const [lineItemAmountOverride, setLineItemAmountOverride] = useState(false);

  const order = useSelector((r: RootState) => ordersSelector.selectById(r, orderId)) as Order;

  const loadRefundAvailability = useCallback(() => {
    setLoading(true);
    dispatch(refundAvailability(orderId))
      .then(unwrapResult)
      .then(setAvailableRefunds)
      .finally(() => setLoading(false));
  }, []);

  useEffect(loadRefundAvailability, [orderId]);

  const getCurrentRefund = () =>
    ({
      orderId,
      refundLineItems: (refunds.refundLineItems || []).filter(({ quantity }) => quantity),
      shippingAmount: dollarsToCents(refunds.shippingAmount || 0),
      lineItemAmount: lineItemAmountOverride ? dollarsToCents(refunds.lineItemAmount || 0) : 0,
      reason: refunds.reason,
    } as Refund);

  const onSubmit = async () => {
    await dispatch(createRefund(getCurrentRefund())).then(unwrapResult);
    message.info("Refund successfully created.");
    setTransactions([]);
    setRefunds({});
    dispatch(clearPaginatedOrders());
    await loadRefundAvailability();
  };

  const setLineItemQuantity = (lineItemId: number, quantity: number) => {
    const refundLineItems: Partial<RefundLineItem>[] = refunds.refundLineItems || [];
    const updatedLineItem = { lineItemId, quantity };
    const existingIndex =
      refunds?.refundLineItems?.findIndex((item) => item.lineItemId === lineItemId) ?? -1;
    if (existingIndex > -1) {
      refundLineItems[existingIndex] = updatedLineItem;
    } else {
      refundLineItems.push(updatedLineItem);
    }
    setRefunds({
      ...refunds,
      refundLineItems: refundLineItems as RefundLineItem[],
      lineItemAmount: 0,
    });
  };

  // TODO: Add debounce
  useEffect(() => {
    const refunds = getCurrentRefund();
    if (refunds.refundLineItems?.length) {
      dispatch(calculateRefundTransactions(refunds))
        .then(unwrapResult)
        .then(({ transactions, maxRefund }) => {
          setTransactions(transactions);
          setMaxRefundable(maxRefund);
        })
        .catch(console.warn);
    } else setTransactions([]);
  }, [refunds]);

  const inventoryLocations = useSelector((s: RootState) =>
    (inventoryLocationSelector.selectAll(s) || []).map((l: any) => ({
      label: l.name,
      value: l.id,
    }))
  );
  useEffect(() => {
    !inventoryLocations.length && dispatch(getInventoryLocations());
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item key="orders">Orders</Breadcrumb.Item>
        <Breadcrumb.Item key="order-id">{orderId}</Breadcrumb.Item>
        <Breadcrumb.Item key="refunds">Refunds</Breadcrumb.Item>
      </Breadcrumb>
      <Col sm={24}>
        <PageHeader
          title={`New Refund for order ${order.name}`}
          className="site-page-header"
          onBack={() => history.push(`/orders/${orderId}`)}
          extra={[]}
        />
      </Col>

      {loading ? (
        <Col sm={24}>
          <Card>
            <Skeleton />
          </Card>
        </Col>
      ) : !availableRefunds?.refundLineItems?.length ? (
        <Col sm={24}>
          <Result
            status="404"
            title="No Line Items"
            subTitle="Sorry, there are no line items that can be refunded."
            extra={
              <Button type="primary" onClick={() => history.push(`/orders/${orderId}`)}>
                Back{" "}
              </Button>
            }
          />
        </Col>
      ) : (
        ""
      )}

      {!loading && availableRefunds?.refundLineItems?.length ? (
        <Row>
          <Col sm={16} style={{ textAlign: "initial" }}>
            <Card title="Line items available for refund">
              {(availableRefunds?.refundLineItems || []).map((item) => {
                const lineItem = order.checkout.lineItems.find(
                  (li: any) => li.id === item.lineItemId
                );
                return (
                  <Space key={item.lineItemId}>
                    <Card style={{ marginBottom: 15 }}>
                      <Row>
                        <Col sm={16}>
                          <Descriptions title={lineItem.variant.product.title} column={2}>
                            <Descriptions.Item label="Variant">
                              {lineItem.variant.title}
                            </Descriptions.Item>
                            <Descriptions.Item label="Quantity">
                              {lineItem.quantity}
                            </Descriptions.Item>
                            <Descriptions.Item label="Base Price per unit">
                              <Money value={lineItem.unitPrice} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Client Price per unit">
                              <Money value={lineItem.unitDiscountedPrice} />
                            </Descriptions.Item>
                          </Descriptions>
                        </Col>
                        <Col sm={8} style={{ display: "flex", alignItems: "center" }}>
                          <Space>
                            <InputNumber
                              onChange={(quantity) =>
                                setLineItemQuantity(item.lineItemId, Number(quantity))
                              }
                              defaultValue={0}
                              step={1}
                              min={0}
                              max={item.quantity}
                            />
                            <Tag>Max {item.quantity}</Tag>
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </Space>
                );
              })}
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Row>
                    <Col sm={14}>Shipping Amount</Col>
                    <Col sm={10}>
                      <InputNumber
                        min={0}
                        defaultValue={0}
                        parser={(value) => Number(value) || 0}
                        onChange={(val) =>
                          setRefunds({ ...(refunds || {}), shippingAmount: Number(val) })
                        }
                        max={centsToDollars(Number(availableRefunds?.shippingAmount))}
                      />
                      <Tag style={{ marginLeft: 10 }}>
                        Max ${centsToDollars(availableRefunds?.shippingAmount as number)}
                      </Tag>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Col sm={14}>{`Override Line Item Refund amount`}</Col>
                    <Col sm={10}>
                      <InputNumber
                        min={1}
                        value={refunds.lineItemAmount || 0}
                        prefix="max %100"
                        disabled={!lineItemAmountOverride}
                        onChange={(val) =>
                          setRefunds({ ...(refunds || {}), lineItemAmount: Number(val) })
                        }
                      />
                      <Tag style={{ marginLeft: 10 }}>
                        Max {formatMoney(maxRefundable.lineItemAmount || 0)}
                      </Tag>
                      <Switch
                        defaultChecked={false}
                        onChange={(v) => {
                          setLineItemAmountOverride(v);
                          setRefunds({ ...refunds });
                        }}
                      />
                    </Col>
                  </Row>
                </Space>
              </Card>
            </Card>
          </Col>
          <Col sm={8} style={{ textAlign: "initial" }}>
            <Card title="Refund Transactions" style={{ height: "100%" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Table dataSource={transactions} rowKey="accountType" pagination={false}>
                  <Table.Column title="Account" dataIndex="accountType" />
                  <Table.Column
                    title="Amount"
                    dataIndex="amount"
                    render={(amt) => "$" + centsToDollars(amt)}
                  />
                </Table>

                <h5 style={{ marginTop: "15px" }}>Refund Reason (optional)</h5>
                <TextArea
                  onChange={(val) => {
                    setRefunds({ ...(refunds || {}), reason: val.target.value });
                  }}
                />
                {transactions.length ? (
                  <Button
                    type="primary"
                    onClick={() =>
                      Modal.confirm({
                        title: "Refund selected transactions.",
                        onOk: onSubmit,
                        okText: "Refund",
                      })
                    }
                    style={{ marginTop: 15 }}
                    block
                  >
                    Refund
                  </Button>
                ) : (
                  ""
                )}
              </Space>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
