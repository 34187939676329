import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "../../api";
import { endpoints } from "../../api/endpoints";
import { Customer } from "../../interfaces/Customer";

export const searchCustomerWithTag = createAsyncThunk(
  "customers/searchWithTag",
  async (tag: string) =>
    api.get(endpoints.customersSearchWithTag, { params: { tag } }).then(extractStandardResponseData)
);
export const searchCustomer = createAsyncThunk("customers/search", async (q: string) =>
  api.get(endpoints.customersSearch, { params: { q } }).then(extractStandardResponseData)
);

export const deleteDeletedCustomer = createAsyncThunk(
  "customers/deleteCustomer",
  async ({ email }: Customer) => api.delete(`/customers/${email}`).then(extractStandardResponseData)
);