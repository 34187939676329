import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Switch } from "antd";
import { Promotion } from "../../interfaces/Promotion";
import { useForm } from "antd/es/form/Form";
import React, { useCallback, useEffect, useState } from "react";
import { clientSelectors } from "../../store/clientsSlice/clientsSlice";
import { useSelector } from "react-redux";
import { PromotionType } from "../../enums/PromotionType";
import { PromotionAllocationMethod } from "../../enums/PromotionAllocationMethod";
import { useAppDispatch } from "../../store/store";
import { createPromotion, updatePromotion } from "../../store/promotionsSlice";
import moment from "moment";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { centsToDollars, dollarsToCents } from "../../utils";

interface IProps {
  promotion?: Partial<Promotion>;
}
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export const PromotionForm = ({ promotion }: IProps) => {
  const dispatch = useAppDispatch();
  const clients = useSelector(clientSelectors.selectAll);
  const [form] = useForm();
  const history = useHistory();

  const [promotionActivation, setPromotionActivation] = useState("automatic");
  const [promotionType, setPromotionType] = useState(PromotionType.FIXED);

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      const formData = form.getFieldsValue();
      formData.value =
        formData.type === PromotionType.FIXED ? dollarsToCents(formData.value) : formData.value;
      formData.minimumCartValue = formData.minimumCartValue
        ? dollarsToCents(formData.minimumCartValue)
        : formData.minimumCartValue;
      const [startsAt, endsAt] = formData.activeRange;
      await (promotion?.id
        ? dispatch(updatePromotion({ ...formData, startsAt, endsAt }))
        : dispatch(createPromotion({ ...formData, startsAt, endsAt }))
      ).then(unwrapResult);
      history.push("/discounts");
    } catch (e) {
      console.error(e);
    }
  }, [form]);

  useEffect(() => {
    if (promotion) {
      form.setFieldsValue({
        ...promotion,
        activeRange: [moment(promotion?.startsAt), moment(promotion?.endsAt)],
        value:
          promotion.type === PromotionType.FIXED
            ? centsToDollars(promotion.value as number)
            : promotion.value,
        minimumCartValue: promotion.minimumCartValue
          ? centsToDollars(promotion.minimumCartValue)
          : promotion.minimumCartValue,
      });
      setPromotionType(promotion.type as PromotionType);
      setPromotionActivation(promotion.code?.length ? "code" : "automatic");
    }
  }, [promotion]);

  return (
    <Form form={form} name="promotion" {...layout}>
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item label="Title" name="title" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Activation"
        name="activation"
        rules={[{ required: true }]}
        initialValue="automatic"
      >
        <Select
          onSelect={(val) => setPromotionActivation(val as string)}
          disabled={!!promotion?.id}
        >
          <Select.Option value="automatic">Automatic</Select.Option>
          <Select.Option value="code">Code</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Code" name="code" rules={[{ required: promotionActivation === "code" }]}>
        <Input disabled={promotionActivation === "automatic"} />
      </Form.Item>
      <Form.Item label="Client" name="clientId">
        <Select
          options={clients.map((c) => ({ label: c.name, value: c.id }))}
          showSearch
          allowClear
          optionFilterProp="name"
          filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
          }
        />
      </Form.Item>
      <Form.Item label="Active" name="active" valuePropName={"checked"}>
        <Switch />
      </Form.Item>
      <Form.Item label="Discount Type" name="type" rules={[{ required: true }]}>
        <Select
          options={Object.values(PromotionType).map((type) => ({
            label: type.split("_").join(" "),
            value: type,
          }))}
          onSelect={(v) => {
            setPromotionType(v as PromotionType);
            if (v === PromotionType.SHIPPING_FEES) {
              form.setFieldsValue({ value: 99999 });
            } else {
              form.setFieldsValue({ value: 0 });
            }
          }}
        />
      </Form.Item>
      <Form.Item
        label="Discount value"
        name="value"
        rules={[{ required: promotionType !== PromotionType.SHIPPING_FEES }]}
      >
        <InputNumber
          min={1}
          formatter={(value) => {
            return `${promotionType === PromotionType.PERCENTAGE ? "%" : "$"} ${
              Number(value) || 0
            }`;
          }}
          parser={(value) =>
            value?.replace(promotionType === PromotionType.PERCENTAGE ? "% " : "$ ", "") || ""
          }
          disabled={
            promotionType === PromotionType.SHIPPING_FEES ||
            promotionType === PromotionType.BUY_X_GET_Y
          }
        />
      </Form.Item>

      <Form.Item label="Minimum Cart Value" name="minimumCartValue">
        <InputNumber
          min={0}
          formatter={(value) => `$${value}`}
          parser={(value) => value?.replace("$", "") || ""}
        />
      </Form.Item>

      <Form.Item label="Minimum Cart Items" name="minimumCartItems">
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item label="Total Usage limit" name="usageLimit">
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item label="Once per customer" name="oncePerCustomer" valuePropName={"checked"}>
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Allocation Method"
        name="allocationMethod"
        rules={[{ required: true }]}
        initialValue={PromotionAllocationMethod.ACROSS}
      >
        <Select
          options={Object.values(PromotionAllocationMethod).map((type) => ({
            label: type,
            value: type,
          }))}
          disabled={promotionType === PromotionType.SHIPPING_FEES}
        />
      </Form.Item>
      <Form.Item
        label="Active time"
        name="activeRange"
        rules={[{ required: true, message: "Select active period" }]}
      >
        <DatePicker.RangePicker showTime />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" onClick={onSubmit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
