import { PricingList } from "../../interfaces/PricingList";
import { useAppDispatch } from "../../store/store";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { Form, Input, InputNumber, Modal } from "antd";
import { pricingListCreate, pricingListUpdate } from "../../store/pricingLists/pricingListSlice";

interface IProps {
  source?: Partial<PricingList>;
  onClose: () => void;
}

const actionType = (source: Partial<PricingList> | undefined) => (source?.id ? "New" : "Edit");

export const PricingListFormModal = ({ onClose, source }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = useForm();

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const formData = form.getFieldsValue();
      source?.id ? dispatch(pricingListUpdate(formData)) : dispatch(pricingListCreate(formData));
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [form]);
  const [pricingList, setPricingList] = useState<Partial<PricingList>>();

  useEffect(() => setPricingList(source), [source]);
  useEffect(() => form?.setFieldsValue(source), [source, form]);
  return (
    <Modal title={`${actionType(source)} Pricing List`} visible onOk={submit} onCancel={onClose}>
      <Form name="pricingList" form={form}>
        <Form.Item hidden name="id" initialValue={pricingList?.id}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Pricing List's title is required" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
