import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Collapse,
  message,
  Modal,
  PageHeader,
  Space,
  Tag,
} from "antd";
import { useSelector } from "react-redux";
import {
  createRefund,
  getOrder,
  ordersSelector,
  refundAvailability,
  resetOrderProperties,
} from "../../store/ordersSlice/ordersSlice";
import { Order } from "../../interfaces/Order";
import { Descriptions, List, Table } from "antd/es";
import { DateTime } from "luxon";
import { NewRefund } from "./refunds/NewRefund";
import { RefundCard } from "./refunds/RefundCard";
import { PromotionsCard } from "./PromotionsCard";
import { Money } from "../../components/Money";
import { formatGatewayName } from "../../utils";
import { PromotionType } from "../../enums/PromotionType";
import { PromotionApplication } from "../../interfaces/Promotion";
import { unwrapResult } from "@reduxjs/toolkit";
import { AccountType } from "../../enums/AccountType";
import { VerificationStatus } from "../../enums/VerificationStatus";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { logout } from "../../store/user/userSlice";
import { Refund } from "../../interfaces/Refund";
import _ from "lodash";
import { LineItem } from "../../interfaces/LineItem";
import { CancelOrderModal } from "./refunds/CancelOrderModal";

const Panel = Collapse.Panel;

const panelStyle = {
  fontWeight: 500,
  fontSize: 16,
  padding: "4px 12px",
};

export const OrderView = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const order = useSelector((s: RootState) => ordersSelector.selectById(s, id)) as Order;
  const [availableRefunds, setAvailableRefunds] = useState<Refund>();
  useEffect(() => {
    id && dispatch(getOrder(id));
  }, [id]);

  const [isOrderRefundable, setIsOrderRefundable] = useState(false);
  useEffect(() => {
    const refundable =
      (order.checkout?.paymentDetails || []).findIndex(
        (pd: any) => pd.accountType === AccountType.COMPANY_ISSUE
      ) === -1;
    setIsOrderRefundable(refundable);
  }, [order]);

  const [cancelOrderSource, setCancelOrderSource] = useState<Order>();

  useEffect(() => {
    dispatch(refundAvailability(order.id))
      .then(unwrapResult)
      .then((availableRefunds) => {
        setAvailableRefunds(availableRefunds);
        !availableRefunds.refundLineItems.length && setIsOrderRefundable(false);
      });
  }, [order]);

  return (
    <Switch>
      <Route exact path={path}>
        <Breadcrumb>
          <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item key="orders" onClick={() => history.push("/orders")}>
            Orders
          </Breadcrumb.Item>
          <Breadcrumb.Item key="order">{order.id}</Breadcrumb.Item>
        </Breadcrumb>

        {order.checkout ? (
          <>
            <Col sm={24}>
              <PageHeader
                title={`${order.id} - (${order.name})`}
                className="site-page-header"
                onBack={() => history.push("/orders")}
                extra={
                  <>
                    <Button
                      type="primary"
                      onClick={() =>
                        Modal.confirm({
                          title: "Are you sure you want reset order properties?",
                          icon: <ExclamationCircleOutlined />,
                          onOk: () => dispatch(resetOrderProperties(order.id)),
                        })
                      }
                    >
                      Reset Order Properties
                    </Button>
                    <Button
                      type="primary"
                      href={`${process.env.REACT_APP_SHOP_URL}/admin/orders/${order.id}`}
                      target="_blank"
                    >
                      View In Shopify
                    </Button>
                    <Button
                      type="primary"
                      target="_blank"
                      onClick={() => history.push(`/orders/${order.id}/refunds`)}
                      disabled={
                        !isOrderRefundable ||
                        !order.paymentCapturedTransactionId ||
                        order.verificationStatus !== VerificationStatus.APPROVED
                      }
                    >
                      Refund
                    </Button>
                    <Button type="primary" href={order.checkout.url} target="_blank">
                      Status Page
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={() => setCancelOrderSource(order)}
                      disabled={
                        !isOrderRefundable ||
                        order.verificationStatus !== VerificationStatus.APPROVED ||
                        _.sum(availableRefunds?.refundLineItems?.map((i) => i.quantity)) !==
                          _.sum(order.checkout.lineItems.map((i: LineItem) => i.quantity))
                      }
                    >
                      Cancel Order
                    </Button>
                  </>
                }
              />
            </Col>
            <Col sm={24} style={{ textAlign: "left" }}>
              <Card>
                <Collapse defaultActiveKey={["order-information"]}>
                  <Panel
                    header="Customer Information"
                    style={panelStyle}
                    key="customer-information"
                    showArrow={false}
                  >
                    <Descriptions>
                      <Descriptions.Item label="Customer name">
                        {order?.customer.firstName} {order?.customer.lastName}
                      </Descriptions.Item>
                      <Descriptions.Item label="Allotment Balance">
                        <Money value={order.customer.allotmentBalance} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Payroll Balance">
                        <Money value={order.customer.payrollBalance} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Minimum Payroll">
                        <Money value={order.customer.minimumPayrollTransactionAmount} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Tags">
                        {order?.customer?.tags?.map((t: any) => (
                          <Tag key={t.id} color="blue">
                            {t.value}
                          </Tag>
                        ))}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>
                  <Panel
                    header="Order Information"
                    key="order-information"
                    style={panelStyle}
                    showArrow={false}
                  >
                    <Space direction="vertical">
                      <Descriptions>
                        <Descriptions.Item label="Order Id">{order.id}</Descriptions.Item>
                        <Descriptions.Item label="Financial Status">
                          {order.financialStatus}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fulfillment Status">
                          <Tag
                            color={
                              order.fulfillmentStatus === "fulfilled" ? "" : "rgb(255, 215, 157)"
                            }
                            style={{ color: "black" }}
                          >
                            {_.capitalize(order.fulfillmentStatus?.replace("_", " "))}
                          </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Gateway">
                          {formatGatewayName(order.gateway)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Verification Status">
                          {order.verificationStatus}
                        </Descriptions.Item>
                        <Descriptions.Item label="Verification at">
                          {DateTime.fromISO(order.verifiedAt).toLocaleString(DateTime.DATETIME_MED)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Verification Note">
                          {order.verificationNote}
                        </Descriptions.Item>
                      </Descriptions>

                      <Card title="Line Items">
                        <List
                          itemLayout="horizontal"
                          dataSource={order.checkout.lineItems}
                          renderItem={(li: any) => (
                            <List.Item>
                              <Descriptions title={li.variant.product.title}>
                                <Descriptions.Item label="SKU">{li.variant.sku}</Descriptions.Item>
                                <Descriptions.Item label="Variant">
                                  {li.variant.title}
                                </Descriptions.Item>
                                <Descriptions.Item label="Quantity">
                                  {li.quantity}
                                </Descriptions.Item>
                                <Descriptions.Item label="Base Price">
                                  <Money value={li.basePrice} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Client Price">
                                  <Money value={li.clientPrice} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Discount Price">
                                  <Money value={li.discountPrice} />
                                </Descriptions.Item>
                              </Descriptions>
                            </List.Item>
                          )}
                        />
                      </Card>

                      {
                        <PromotionsCard
                          promotionApplications={order.checkout.promotionApplications}
                        />
                      }
                    </Space>
                  </Panel>
                  <Panel header="Payment Information" key="3" style={panelStyle} showArrow={false}>
                    <Descriptions>
                      <Descriptions.Item label="Sub Total">
                        <Money value={order.checkout.subTotal} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Total Tax">
                        <Money value={order.checkout.totalTax} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Shipping Fees">
                        <Money
                          value={
                            order.checkout?.promotionApplications?.find(
                              (pa: PromotionApplication) =>
                                pa.promotion.type === PromotionType.SHIPPING_FEES
                            )
                              ? 0
                              : order.checkout.shippingFees
                          }
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Total">
                        <Money value={order.checkout.total} />
                      </Descriptions.Item>
                    </Descriptions>
                    {order.checkout.paymentDetails?.length ? (
                      <Card title="Payment Breakdown">
                        <Table
                          pagination={false}
                          dataSource={order.checkout.paymentDetails}
                          rowKey={"id"}
                          summary={() => (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                  <strong>Total</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2}>
                                  <strong>
                                    <Money
                                      value={(order.checkout.paymentDetails || []).reduce(
                                        (sum: number, pd: any) => sum + pd.amount,
                                        0
                                      )}
                                    />
                                  </strong>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          )}
                        >
                          <Table.Column
                            title="Gateway"
                            key="gateway"
                            dataIndex="gateway"
                            render={formatGatewayName}
                          />
                          <Table.Column
                            title="Account Type"
                            key="accountType"
                            dataIndex="accountType"
                            render={(val: string) => val.split("_").join(" ")}
                          />
                          <Table.Column
                            title="Amount"
                            key="amount"
                            dataIndex="amount"
                            render={(val) => <Money value={val} />}
                          />
                        </Table>
                      </Card>
                    ) : (
                      ""
                    )}
                    <Card title="Tax Breakdown">
                      <Table
                        pagination={false}
                        dataSource={order.taxLines}
                        rowKey="id"
                        summary={() => (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>
                                <strong>Total Tax</strong>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1} />
                              <Table.Summary.Cell index={2}>
                                <strong>
                                  <Money value={order.checkout.totalTax} />
                                </strong>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        )}
                      >
                        <Table.Column title="Title" dataIndex={"title"} />
                        <Table.Column title="Rate" dataIndex={"rate"} />
                        <Table.Column
                          title="Price"
                          dataIndex={"amount"}
                          render={(val) => <Money value={val} />}
                        />
                      </Table>
                    </Card>
                  </Panel>
                  <Panel key="refunds" header="Refunds" style={panelStyle} showArrow={false}>
                    <List
                      dataSource={order.refunds || []}
                      renderItem={(r) => <RefundCard refund={r} />}
                      rowKey="id"
                    />
                  </Panel>
                </Collapse>
              </Card>
            </Col>
            {cancelOrderSource && (
              <CancelOrderModal
                order={cancelOrderSource}
                onClose={() => {
                  setCancelOrderSource(undefined);
                  dispatch(getOrder(id));
                }}
              />
            )}
          </>
        ) : (
          <>
            <Col sm={24}>
              <h3>Checkout not found</h3>
            </Col>
          </>
        )}
      </Route>
      <Route path={`${path}/refunds`} component={NewRefund} />
    </Switch>
  );
};
