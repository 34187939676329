import { useDispatch } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { Form, Input, InputNumber, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DiscountedPrice } from "../../interfaces/DiscountedPrice";
import {
  pricingListProductUpdate,
  pricingListVariantUpdate,
} from "../../store/pricingLists/pricingListSlice";

export type DiscountedPriceFormModalSource = DiscountedPrice & { productId?: number };

interface IProps {
  discountedPrice: DiscountedPriceFormModalSource | undefined;
  onClose: () => void;
}
export const DiscountedPriceFormModal = ({ onClose, discountedPrice }: IProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();

  const submit = useCallback(() => {
    dispatch(
      discountedPrice?.productId
        ? pricingListProductUpdate({
            ...form.getFieldsValue(),
            productId: discountedPrice.productId,
            pricingListId: discountedPrice.pricingListId,
          })
        : pricingListVariantUpdate({
            ...form.getFieldsValue(),
            variantId: discountedPrice?.variantId,
            pricingListId: discountedPrice?.pricingListId,
          })
    );
    onClose();
  }, [form]);

  const [dp, setDp] = useState<DiscountedPriceFormModalSource>();
  useEffect(() => setDp(discountedPrice), [discountedPrice]);

  if (!dp) {
    return <></>;
  }

  return (
    <Modal
      title="Edit Discounted Pricing"
      visible={!!discountedPrice}
      onOk={submit}
      onCancel={() => {
        onClose();
      }}
    >
      <Form name="client" form={form}>
        <Form.Item hidden name="id" initialValue={dp.id}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Discounted Price"
          name="price"
          initialValue={dp.price}
          rules={[{ required: true }]}
        >
          <InputNumber formatter={(v) => `$ ${v}`} min={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
