import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Order } from "../../interfaces/Order";
import { FinancialStatus } from "../../enums/FinancialStatus";
import { VerificationStatus } from "../../enums/VerificationStatus";
import { api, extractStandardResponseData } from "../../api";
import { endpoints } from "../../api/endpoints";
import { RootState } from "../store";
import { Refund } from "../../interfaces/Refund";

export const orderAdapter = createEntityAdapter<Order>();

export const ordersIndex = createAsyncThunk(
  "orders/index",
  async (filters: {
    from?: Date;
    to?: Date;
    financialStatus?: FinancialStatus;
    verificationStatus?: VerificationStatus;
    companyTag?: string;
    page?: number;
    searchTerm?: string;
  }) => api.get(endpoints.ordersIndex, { params: filters }).then((res) => res.data)
);

export const getOrder = createAsyncThunk("orders/getOrder", (orderId: number) =>
  api.get(`/orders/${orderId}`).then(extractStandardResponseData)
);

export const refundAvailability = createAsyncThunk(
  "orders/refundAvailability",
  async (orderId: number) =>
    api.get(`/orders/${orderId}/refunds/availability`).then(extractStandardResponseData)
);

export const calculateRefundTransactions = createAsyncThunk(
  "orders/calculateRefundTransactions",
  async (refund: Refund) =>
    api
      .post(`/orders/${refund.orderId}/refunds/calculate`, refund)
      .then(extractStandardResponseData)
);

export const createRefund = createAsyncThunk("orders/createRefund", async (refund: Refund) =>
  api.post(`/orders/${refund.orderId}/refunds`, refund).then(extractStandardResponseData)
);

export const getRawOrder = createAsyncThunk("orders/gatRawOrder", (orderId: number) =>
  api.get(`/orders/${orderId}/raw`).then(extractStandardResponseData)
);

export const cancelOrder = createAsyncThunk(
  "orders/cancelOrder",
  async (payload: { notify: boolean; restock: boolean; orderId: number }) => {
    return api.post(`/orders/${payload.orderId}/cancel`, payload);
  }
);

export const resetOrderProperties = createAsyncThunk(
  "orders/resetOrderProperties",
  async (orderId: number) => {
    return api.post(`/orders/${orderId}/reset_properties`);
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState: orderAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ordersIndex.fulfilled, (state, { payload: { data } }) => {
      return orderAdapter.upsertMany(state, data);
    });
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      return orderAdapter.upsertOne(state, payload);
    });
  },
});

export const ordersSelector = orderAdapter.getSelectors((s: RootState) => s.orders);
