import React, { useEffect, useState } from "react";
import { centsToDollars } from "../utils";

interface IProps {
  value: number | string;
}

export const Money = ({ value }: IProps) => {
  const [amount, setAmount] = useState<string>();
  useEffect(() => setAmount(centsToDollars(value).toFixed(2)), [value]);
  return <span>${amount}</span>;
};
