import { PromotionApplication } from "../../interfaces/Promotion";
import { Table } from "antd/es";
import { Card } from "antd";
import React from "react";
import { Money } from "../../components/Money";

interface IProps {
  promotionApplications: PromotionApplication[];
}
export const PromotionsCard = ({ promotionApplications }: IProps) => {
  return (
    <Card title="Discounts">
      <Table
        pagination={false}
        dataSource={promotionApplications || []}
        size="small"
        rowKey={"id"}
        summary={() => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <strong>
                  <Money
                    value={(promotionApplications || []).reduce(
                      (sum: number, pd: any) => sum + pd.value,
                      0
                    )}
                  />
                </strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      >
        <Table.Column title="Title" render={(pa) => pa.promotion.title} />
        <Table.Column title="Type" render={(pa) => pa.promotion.type} />
        <Table.Column title="Value" render={(pa) => <Money value={pa.value} />} />
      </Table>
    </Card>
  );
};
