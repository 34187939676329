import React, { useCallback, useEffect } from "react";
import { Checkbox, Form, Input, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { pricingListSelectors } from "../../store/pricingLists/pricingListSlice";
import { collectionSelectors } from "../../store/collectionSlice/collectionsSlice";
import { Client } from "../../interfaces/Client";
import { clientCreate, clientUpdate } from "../../store/clientsSlice/clientsSlice";

interface IProps {
  client: Partial<Client>;
  onClose: () => void;
}
export const ClientFormModal = ({ client, onClose }: IProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      client?.id
        ? dispatch(clientUpdate(form.getFieldsValue()))
        : dispatch(clientCreate(form.getFieldsValue()));

      onClose();
    } catch (e) {
      console.warn(e);
    }
  }, [form]);

  useEffect(() => {
    form && form.setFieldsValue(client);
  }, [client, form]);

  const collections = useSelector((state: RootState) =>
    collectionSelectors
      .selectAll(state)
      .map((collection) => ({ label: collection.title, value: collection.id }))
  );

  const pricingLists = useSelector((state: RootState) =>
    pricingListSelectors.selectAll(state).map((pl) => ({ label: pl.title, value: pl.id }))
  );

  return (
    <Modal
      title={`${client?.id ? "Edit" : "New"} Client`}
      visible={true}
      onOk={submit}
      onCancel={onClose}
    >
      <Form name="client" form={form}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Client's name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tag"
          name="tag"
          rules={[{ required: true, message: "Client's tag is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Collection" name="collectionId">
          <Select
            options={collections}
            showSearch
            optionFilterProp="title"
            filterOption={(input, option) =>
              (option?.label as string)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>

        <Form.Item label="PricingList" name="pricingListId">
          <Select options={pricingLists} />
        </Form.Item>

        <Form.Item label="Base Price list name" name="basePriceList">
          <Input />
        </Form.Item>

        <Form.Item
          label="Enable Registration"
          name="enableRegistration"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};
